export enum Darkrooms {
    AllCursed = -4,
    ManyCursed = -3,
    SomeCursed = -2,
    FewCursed = -1,
    None = 0,
    Few = 1,
    Some = 2,
    Many = 3,
    All = 4
}